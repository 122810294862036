<template>
  <form
      @submit.prevent="submitRepair"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :enableLogicalBack="true" back-to=" ">

      <div v-if="!isBusy">
        <div v-for="button in currentStepButtons" :key="button.label" class="inline-block">
          <AppButton
              :color="button.color"
              :label="button.label"
              class="ml-2"
              @click="button.action"
          >
            <template v-slot:icon v-if="button.icon">
              <Icon :iconType="button.icon" class="mr-2"/>
            </template>
          </AppButton>
        </div>
      </div>

    </page-header>

    <main class="pr-6 pl-6 pb-6 bg-background flex-1 overflow-y-scroll">

      <!-- Equipment Details -->

      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Equipment Details
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
            <AppButton color="green" label="Find & Select Equipment" :icon=true class="ml-2"
                       @click="clickFindEquipmentPanel()">
              <template v-slot:icon>
                <Icon iconType="search" class="mr-2"/>
              </template>
            </AppButton>
          </div>
          <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Code</label>
              <input disabled v-model="repair.product_code" class="bge-input bge-input-spacing rounded" type="text"/>
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm"
              >CUSTOMER</label
              >
              <input disabled v-model="repair.customer.name" class="bge-input bge-input-spacing rounded" name="bin"
                     type="text"/>
              <!--              <ContactAutocomplete v-model="repair.customer" :is_customer="true"/>-->
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Location</label>
              <input v-model="repair.location" class="bge-input bge-input-spacing rounded" name="bin" type="text"/>
            </div>

            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Description</label>
              <input disabled v-model="repair.product_description" class="bge-input bge-input-spacing rounded"
                     type="text"/>
            </div>

            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Serial Number</label>
              <div class="relative">
                <input disabled v-model="repair.serial_number"
                       class="bge-input bge-input-spacing rounded w-full" type="text"/>
                <div v-if="isValidating" class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                  Validating...
                </div>
                <div v-if="isValid !== null && !isValidating" class="absolute inset-y-0 right-0 pr-3 flex items-center">
                 <span v-if="isValid" class="text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                       <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                    </svg>
                </span>
                  <span v-else class="text-red-500">
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                   </svg>
                 </span>
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Rack Number</label>
              <input v-model="repair.rack_number" class="bge-input bge-input-spacing rounded" type="text"/>
            </div>

            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Date Received</label>
              <Calendar
                  disabled
                  class="w-full lg:w-auto"
                  v-model="repair.created_date"
                  dateFormat="dd/mm/yy"
              />
            </div>
            <div class="flex flex-col">
              <label class="uppercase font-semibold text-sm">Goods In Ref</label>
              <div v-if="repair.goods_in_id && repair.goods_in_reference_id">
                <a :href="'/internal/goods-in/edit/' + repair.goods_in_id"
                   target="_blank" rel="noopener noreferrer"
                   class="bge-input bge-input-spacing rounded block text-blue-500 hover:text-blue-700 focus:underline">
                  View Goods In: {{ repair.goods_in_reference_id }}
                </a>
              </div>
              <div v-else class="bge-input bge-input-spacing rounded">
                Reference ID not available
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Notes and Description -->
      <div class="flex flex-col lg:flex-row w-full">
        <!-- Column 1 -->
        <div class="w-full lg:w-1/2 bg-white p-5 mt-6 lg:mr-3 rounded shadow-md">
          <div class="flex items-center mb-2">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Goods-In Notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>

          <Editor editorStyle="height: 120px" v-model="repair.goods_in.notes" :options="editorOptions">
            <template #toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
            </template>
          </Editor>
        </div>
        <!-- Column 2 -->
        <div class="w-full lg:w-1/2 bg-white p-5 mt-6 lg:ml-3 rounded shadow-md">
          <div class="flex items-center mb-2">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Description of Issue
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <Editor editorStyle="height: 120px" v-model="repair.description_of_issue" :options="editorOptions">
            <template #toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
            </template>
          </Editor>
        </div>
      </div>

      <!-- Status -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <!-- Header with Vertical Line -->
        <div class="flex items-center mb-5 mt-6">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
            Current Status
          </h3>
          <hr class="flex-1 border-primary mx-4"/>
        </div>

        <!-- Two Columns for Controls (Responsive) -->
        <div class="flex flex-col lg:flex-row">
          <!-- Left Column Control -->
          <div class="w-full lg:w-1/2 lg:pr-2 mb-4 lg:mb-0">
            <Timeline :value="repair.statuses">
              <template #opposite="slotProps">
                <small>{{ slotProps.item.created_by }} , {{
                    slotProps.item.date_formatted
                  }}</small>
              </template>
              <template #content="slotProps">

                {{ slotProps.item.status }}
              </template>
            </Timeline>
          </div>

          <!-- Right Column Control -->
          <div class="w-full lg:w-1/2 lg:pl-2">
            <label class="uppercase font-semibold text-sm">New Status</label>
            <Status ref="statusRef" :initialNotes="Status"
                    @items-added="onNotesAdded"></Status>
          </div>
        </div>
      </div>
    </main>

    <portal to="overlay-outlet">
      <panel
          :showing="findEquipmentPanelOpen"
          @close="findEquipmentPanelOpen = false"
          title="Find Equipment"
      >
        <FindEquipment
            @complete="applyEquipmentDetails"
        />
      </panel>
    </portal>
  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import Status from "@/views/HR/Repair/Components/Status.vue";
import FindEquipment from "@/views/HR/Repair/Components/FindEquipment.vue";
import printJS from "print-js";

const Toggle = () => import("@/components/Toggle.vue");
export default {
  name: "ManageRepair",
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    AppButton,
    Icon,
    Toggle,
    Status,
    FindEquipment,
  },
  props: {
    repair_id: String,
    equipment_id: String,
    product_code: String,
  },
  data() {
    return {
      isBusy: false,
      currentStep: 'Start',
      serialNumber: '',
      isValid: null,
      isValidating: false,
      findEquipmentPanelOpen: false,
      repair: {
        customer: {},
        goods_in: {},
      },
      Status: [],
      events: [
        {
          status: 'Comprehensive diagnostics and repair for automotive garage equipment and tools',
          date: '15/10/2020 10:30',
        },
        {
          status: 'Comprehensive diagnostics and repair for automotive garage equipment and tools',
          date: '15/10/2020 14:00',
        },
        {status: 'Shipped', date: '15/10/2020 16:15',},
        {status: 'Delivered', date: '16/10/2020 10:00',}
      ],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },

    };
  },
  async mounted() {

    if (this.repair_id) {
      this.isBusy = true;
      const repair = await this.loadRepairByRepairId();

      const formattedRepair = this.formatRepairData(repair);

      this.repair = {
        ...this.repair,
        ...formattedRepair,
      }
      await this.validateSerialNumber();
      this.isBusy = false;
      return;
    }

    if (this.equipment_id) {
      await this.loadEquipment(this.equipment_id);
    }

  },

  computed: {

    pageTitle() {
      return this.repair_id ? "Update Repair" : "Create Repair";
    },

    currentStepButtons() {
      const stepButtons = {
        'new': [
          {label: 'Save Repair', color: 'blue', action: this.saveRepair},
          {label: 'Set Under Review', color: 'indigo', action: this.setStatusUnderReview},
        ],
        'review': [
          {label: 'Quotation', color: 'green', action: this.manageQuotation},
          {label: 'Set Completed', color: 'indigo', action: this.setStatusCompleted},
        ],
        'quotation': [
          {label: 'Quotation', color: 'green', action: this.manageQuotation},
          {label: 'Set Completed', color: 'indigo', action: this.setStatusCompleted},
        ],
      };

      return stepButtons[this.currentStep] || [
        {label: 'Save Repair', color: 'blue', action: this.saveRepair}
      ];
    },
  },
  methods: {

    async clickFindEquipmentPanel() {
      this.findEquipmentPanelOpen = true;
    },

    async applyEquipmentDetails(data) {
      if (!data) {
        return;
      }

      this.repair.product_code = data.product_code || '';
      this.repair.product_id = data.product_id || '';
      this.serialNumber = this.repair.serial_number = data.serial_number || '';
      this.repair.product_description = data.name || '';
      this.repair.equipment_id = data.equipment_id || '';

      if (data.owner) {
        this.repair.customer = {};
        this.repair.customer.name = data.owner.name || '';
        this.repair.customer.contact_id = data.owner.contact_id || '';
      }

      await this.validateSerialNumber();

      this.findEquipmentPanelOpen = false;
    },

    async validateSerialNumber() {

      if (!this.serialNumber || this.serialNumber.length <= 0) {
        this.isValid = false;
        return;
      }
      try {
        this.isValidating = true;
        const equipment = await this.EquipmentService.getEquipmentItemBySerialNumber(this.serialNumber)
        if (equipment) {
          this.isValid = true;
        }
      } catch (e) {

        this.isValid = false;
      } finally {
        this.isValidating = false;
      }
    },

    formatRepairData(repair) {
      let formattedRepair = JSON.parse(JSON.stringify(repair));

      if (formattedRepair.created_at) {
        let createdMoment = this.$moment.unix(formattedRepair.created_at);
        formattedRepair.created_date = createdMoment.toDate();
      }

      if (formattedRepair.statuses) {
        formattedRepair.statuses = formattedRepair.statuses.map(statusEntry => {
          if (statusEntry && statusEntry.date) {
            let statusMoment = this.$moment.unix(statusEntry.date);
            statusEntry.date_formatted = statusMoment.format('DD-MM-YYYY HH:mm');
          }
          return statusEntry;
        });
      }

      this.currentStep = formattedRepair.workflow_status;

      if (!formattedRepair.goods_in) {
        formattedRepair.goods_in = {};
      }

      if (!formattedRepair.customer) {
        formattedRepair.customer = {};
      }

      this.serialNumber = formattedRepair.serial_number

      return formattedRepair;
    },

    onNotesAdded() {
      this.selectedPreSetText = null;
    },

    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },

    async loadRepairByRepairId() {
      try {


        const repairData = await this.RepairService.getRepairById(this.repair_id);
        if (!repairData) {
          return;
        }
        return repairData;

      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    async loadEquipment(equipmentId) {
      try {

        const equipmentData = await this.EquipmentService.getEquipmentItemById(equipmentId);

        if (!equipmentData) {
          return;
        }


        this.repair = {
          ...this.repair,
          product_code: equipmentData.product_code,
          product_type: equipmentData.product_type,
          serial_number: equipmentData.serial_number,
          product_description: equipmentData.name,
          customer: {
            name: equipmentData.owner ? equipmentData.owner.name : "",
            contact_id: equipmentData.owner ? equipmentData.owner.contact_id : "",
          },
          equipment_id: equipmentData.equipment_id
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    formattedReceivedDate(repair) {
      let repairData = JSON.parse(JSON.stringify(repair));

      return repairData;
    },

    getAllRepairData(repair) {
      let formattedRepair = this.formattedReceivedDate(repair);
      this.updateStatuses(formattedRepair);
      return formattedRepair;
    },

    getStatusesData() {
      if (this.$refs.statusRef) {

        const allStatus = this.$refs.statusRef.getCurrentNotes();
        const nonEmptyStatus = allStatus.filter(note => note.text && note.text.trim().length > 0);
        return nonEmptyStatus;
      }
    },

    updateStatuses(repair) {
      const newStatuses = this.getStatusesData();
      const updatedStatuses = newStatuses.map(note => ({
        status: note.text,
      }));

      if (updatedStatuses && updatedStatuses.length > 0) {
        repair.statuses = updatedStatuses;
      } else {
        repair.statuses = [];
      }

      return repair;
    },

    addStatus(repair, statusText) {
      repair.statuses.push({
        status: statusText,
      });
    },

    manageQuotation: async function () {

      if (!this.repair || !this.repair.repair_id) {
        return;
      }
      if (this.repair.quotation_id) {
        await this.$router.push({
          name: "quotation-edit",
          params: {
            quotation_id: this.repair.quotation_id
          },
        });
      } else {
        await this.$router.push({
          name: "quotation-create",
          query: {
            equipment_id: this.repair.equipment_id,
            repair_id: this.repair.repair_id,
            product_code: this.repair.product_code,
          },
        });
      }
    },

    validateData() {
      if (!this.repair.product_code) {
        this.notifyWarning('Product Code is required.');
        return false;
      }
      // if (!this.repair.serial_number) {
      //   this.notifyWarning('Serial Number is required.');
      //   return false;
      // }
      if (!this.repair.product_description) {
        this.notifyWarning('Product Description is required.');
        return false;
      }
      if (this.repair.customer && !this.repair.customer.contact_id) {
        this.notifyWarning('Customer Contact ID is required.');
        return false;
      }
      return true;
    },

    setStatusUnderReview: async function () {

      if (!this.validateData()) {
        return;
      }

      const formattedRepair = this.getAllRepairData(this.repair);
      this.addStatus(formattedRepair, 'Under Review');
      formattedRepair.workflow_status = 'review'
      await this.save(formattedRepair);
    },

    setStatusCompleted: async function () {

      if (!this.validateData()) {
        return;
      }

      const formattedRepair = this.getAllRepairData(this.repair);
      this.addStatus(formattedRepair, 'Completed');
      formattedRepair.workflow_status = 'completed'
      await this.save(formattedRepair);
    },

    saveRepair: async function () {
      if (!this.validateData()) {
        return;
      }
      this.isBusy = true;
      const formattedRepair = this.getAllRepairData(this.repair);
      await this.save(formattedRepair);
    },

    save: async function (repair) {
      this.isBusy = true;
      try {

        if (this.repair_id) {
          await this.RepairService.updateRepair(
              this.repair_id,
              repair,
          );
          this.notifySuccess("Repair details updated successfully");
        } else {
          repair.source_type = "manual";
          repair.workflow_status = "new";
          await this.RepairService.createRepair(repair);
          this.notifySuccess("Repair details created successfully");
        }

        this.$router.go(-1);

      } catch (error) {
        let errorMessage = this.incident_id
            ? "Couldn't update Repair details"
            : "Couldn't create Repair details";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitRepair: async function (event) {
      event.preventDefault();
    },
  }
}
;
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

</style>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>