<template>
  <form @submit.prevent="applyEquipmentDetails" class="flex flex-wrap">

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
      ></label
      >
      <Toggle
          label="Has Serial Number"
          :labelPosition="'left'"
          labelClasses="uppercase font-semibold text-sm"
          :checked="hasSerialNumber"
          @value-changed="(value) => (hasSerialNumber = value)"
      />
    </div>
    <div class="w-full py-2" v-if="hasSerialNumber">
      <label class="uppercase font-semibold text-sm"
      ></label
      >
      <EquipmentAutoComplete class="w-full" v-model="selectedEquipmentAutoComplete"
                             @equipmentSelected="handleEquipmentSelected"
                             @equipmentCleared="handleEquipmentCleared" :forceSelection="true"/>
    </div>

    <div class="w-full py-2" v-if="!hasSerialNumber">
      <label class="uppercase font-semibold text-sm"
      >Customer</label
      >
      <ContactWithAddressAutoComplete class="w-full" v-model="selectedCustomerAutoComplete" :is_customer="true"
                                      :forceSelection="true"/>
    </div>
    <div class="w-full py-2" v-if="!hasSerialNumber">
      <label class="uppercase font-semibold text-sm"
      >Product</label
      >
      <ProductAutoComplete class="w-full" v-model="selectedProductAutoComplete"
                           :forceSelection="true"/>
    </div>


    <div v-if="selectedEquipment" class="mt-8 p-4 border border-gray-200 rounded-lg  w-full">
      <div class="grid grid-cols-2 gap-4">
        <div class="uppercase font-semibold text-left">Product Code:</div>
        <div class="text-right"><a :href="`/products/${selectedEquipment.product_code}`" target="_blank"
                                   rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600">
          {{ selectedEquipment.product_code }}
        </a></div>

        <div class="uppercase font-semibold text-left">Description:</div>
        <div class="text-right">{{ selectedEquipment.name }}</div>

        <div class="uppercase font-semibold text-left">Serial Number:</div>
        <div class="text-right"><a :href="`/equipment/${selectedEquipment.equipment_id}`" target="_blank"
                                   rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600">
          {{ selectedEquipment.serial_number }}
        </a></div>

        <div class="uppercase font-semibold text-left">Owner Name:</div>
        <div class="text-right"><a :href="`/contacts/${getPropertyValue(selectedEquipment.owner, 'contact_id')}`"
                                   target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600">
          {{ getPropertyValue(selectedEquipment.owner, 'name') }}
        </a></div>

        <div class="uppercase font-semibold text-left">Service Agent Name:</div>
        <div class="text-right">{{ getPropertyValue(selectedEquipment.service_agent, 'name') }}</div>

        <div class="uppercase font-semibold text-left">Seller Agent Name:</div>
        <div class="text-right">{{ getPropertyValue(selectedEquipment.seller_agent, 'name') }}</div>
      </div>
    </div>


    <div class="w-full">

      <button v-if="selectedEquipment || (selectedCustomerAutoComplete && selectedProductAutoComplete)"
              type="button"
              @click="applyEquipmentDetails"
              class="flex items-center float-right mt-5 rounded border bg-green-200 border-green-400 text-green-800 py-1 px-3 hover:bg-green-300 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8"/>
        </svg>
        <span>Apply Equipment Details</span>
      </button>
    </div>
  </form>
</template>

<script>
import {COUNTRIES} from "@/utils/constants";
import {DEPARTMENTS} from "@/utils/constants";
import Notification from "@/components/Notification.vue";

const EquipmentAutoComplete = () => import("@/components/AutoComplete/EquipmentAutoComplete.vue");
const ProductAutoComplete = () => import("@/components/AutoComplete/ProductAutoComplete.vue");
const ContactWithAddressAutoComplete = () => import("@/components/AutoComplete/ContactWithAddressAutoComplete.vue");
import auth from "@/utils/auth";
import LoanEquipmentAutoComplete from "@/components/AutoComplete/LoanEquipmentAutoComplete.vue";

const Toggle = () => import("@/components/Toggle.vue");

export default {
  name: "FindEquipment",
  components: {
    LoanEquipmentAutoComplete,
    ProductAutoComplete,
    EquipmentAutoComplete,
    ContactWithAddressAutoComplete,
    Toggle
  },
  data() {
    return {
      selectedEquipmentAutoComplete: null,
      selectedEquipment: null,
      selectedCustomerAutoComplete: null,
      selectedProductAutoComplete: null,
      hasSerialNumber: true,
    };
  },
  mounted() {

  },
  methods: {

    updateSearchOptions: function (value) {
      debugger
    },
    applyEquipmentDetails: function () {

      if (this.hasSerialNumber) {
        if (!this.selectedEquipment) {
          return;
        }
        this.$emit("complete", this.selectedEquipment);
      } else {

        if (!this.selectedCustomerAutoComplete || !this.selectedProductAutoComplete) {
          return;
        }
        const data = {
          product_code: this.selectedProductAutoComplete.product_code,
          name: this.selectedProductAutoComplete.name,
          product_id: this.selectedProductAutoComplete.product_id,
          owner: {
            name: this.selectedCustomerAutoComplete.name,
            contact_id: this.selectedCustomerAutoComplete.contact_id
          }
        };

        this.$emit("complete", data);
      }

    },

    async handleEquipmentSelected() {
      if (this.selectedEquipmentAutoComplete && this.selectedEquipmentAutoComplete.equipment_id) {
        this.selectedEquipment = await this.EquipmentService.getEquipmentItemById(this.selectedEquipmentAutoComplete.equipment_id);
      }
    },

    async handleEquipmentCleared() {
      this.selectedEquipment = null;
    },


    getPropertyValue(object, key) {
      if (object && object.hasOwnProperty(key)) {
        return object[key];
      }
      return '';
    }
  },
};
</script>
